import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import SearchResults from '../SearchResults/SearchResults';
import Highlights from '../Highlights/Highlights';
import IngredientSlider from "../Ingredients/IngredientSlider";

const TabContainer = (props) => {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
};

const CustomizedTabs = (props) => {
    console.log("CustomizedTabs are rendering", props.ingredients);
    const {classes} = props;
    const [value, setValue] = useState(0);
    const [tabs, setTabs] = useState(["Recipes", "Ingredients", "Videos"]);
    // const [tabs, setTabs] = useState(["Items", "Menus", "Restaurants", "Pick-up", "Delivery"]);
    // const [tabs, setTabs] = useState(["Home", "Sales", "Expenses", "Items", "Reports", "Banking"]);

    const [searchResults, setResults] = useState(props.results);
    // console.log("searchResults in CustomizedTabs are: ", searchResults)
    const [ingredients, setIngredients] = useState(props.ingredients);
    const [loading, setLoading] = useState(undefined);

    const handleChange = (event, value) => {
        setValue(value);
    };

    const mappedTabs = tabs.map((tab, index) => {
        return (
            <Tab
                key={index}
                disableRipple
                classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                label={tab}
            />
        );
    });
    useEffect(() => {
        console.log("useEffect in Header fired");
        setResults(props.results);
        // console.log("searchResults in Header are:", searchResults)
        setLoading(1);
        console.log("loading is:", loading);


    }, []);
    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
            >
                {mappedTabs}
            </Tabs>


            {value === 0 &&
            <TabContainer>
                <Highlights
                    results={props.results}
                />
            </TabContainer>
            }
            {value === 1 &&
            <TabContainer>
                <IngredientSlider
                    ingredients={props.ingredients}
                />
            </TabContainer>
            }
            {value === 2 &&
            <TabContainer>
                {/* <Games/> */} 3
            </TabContainer>
            }
        </div>);
};

CustomizedTabs.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(CustomizedTabs);

import React, {useState, useEffect} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import youtubeImg from '../../youtube.png';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import _ from 'lodash';
import LinesEllipsis from 'react-lines-ellipsis'



const Highlights = (props) => {
    console.log("Highlights is rendering");
    const {classes} = props;
    const results = props.results;
    const [currentResults, setCurrentResults] = useState(results)
    const range = 25;
    const [rangeResults, setRangeResults] = useState(25);
    const [value, setValue] = React.useState(4);

    useEffect(() => {
        setCurrentResults(results);
        setRangeResults(25);
    }, [results])

    if (!currentResults) {
        return null
    }

    const handleClick = () => {
        setRangeResults(rangeResults + range);
    }

    const parseIngredients = (ingredientList) => {
        let arr_from_json = JSON.parse(ingredientList);
        let newList = []
        arr_from_json.map((ingredient) => {
            newList.push(_.startCase(ingredient )+ ", ")
        })
        return (newList)
    }

    const mappedHighlights = currentResults.map((highlight, index) => {
        const ingredientList = parseIngredients(highlight.recipe_ingredients)
        return (
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2} className={classes.gridTile} key={index}>
                <Card className={classes.root}>

                    <CardMedia
                        className={classes.media}
                        image={highlight.recipe_image_url}
                    />

                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.title} color="textPrimary" gutterBottom>
                            {highlight.recipe_display_name}
                        </Typography>

                        <Typography className={classes.ingredientText}>

                            <LinesEllipsis
                                text={ingredientList}
                                maxLine='3'
                                ellipsis='...'
                                trimRight
                                basedOn='letters'
                            />
                        </Typography>
                        <Typography>
                            <Rating name="read-only" value={value} readOnly size="small"/>
                        </Typography>
                    </CardContent>

                </Card>
                {/*<div className={classes.tile}>*/}
                {/*    <div className={classes.tileMedia}>*/}
                {/*        <a href={highlight.highlight_url}>*/}
                {/*            <img className={classes.tileMediaImg} src={highlight.recipe_image_url} alt="img"/>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className={classes.tileBody}>*/}
                {/*        <h6 className={classes.tileTitle}>{highlight.highlight_title}</h6>*/}
                {/*        <span>*/}
                {/*        /!*<img alt="img" src={youtubeImg} className={classes.tileFavicon}/>*!/*/}
                {/*        <a target="_blank" rel="noopener noreferrer" href={highlight.highlight_url}*/}
                {/*           className={classes.videoSource}>{highlight.recipe_display_name}</a>*/}
                {/*    </span>*/}
                {/*        <p className={classes.recipeText}>This is where some recipe stuff goes</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Grid>
        )
    })

    return (
        <div className={classes.root}>
            <Grid container>
                {mappedHighlights.slice(0, rangeResults)}
            </Grid>
            {rangeResults >= currentResults.length ? null :
                <Button variant="outlined" className={classes.buttonShowMore} onClick={handleClick}>Show More
                    Results</Button>}
        </div>
    )
};

Highlights.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Highlights);
const styles = theme => ({
    form: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "#333333",
        width: 585,
        height: 40,
        boxShadow: '0 2px 3px rgba(0,0,0,0.06)',
        border: '1px solid rgba(0,0,0,0.15)',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    input: {
        marginLeft: 8,
        flex: 1,
        color:"#eeeeee"
    },
    iconButton: {
        padding: 10,
        borderRadius: '0',
        color: "#999999",
        position: 'relative',
        left: 5,
        iconHover: {
            '&:hover': {
                color: 'blue',
            },
        },
    },
    iconMic: {
        padding: 10,
        borderRadius: '0',
        position: 'relative',
        color: "#999999",
        left: 5,
        '&:hover': {
            background: 'none',
        },
        iconHover: {
            '&:hover': {
                color: 'blue',
            },
        },
    }
});

export default styles;
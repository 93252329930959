export default {
    'height': 'height',
    'weight': 'weight',
    'position_name': 'position',
    'high_school': 'high school',
    'city':'city',
    'state_abbr':'state',
    'graduation_year': 'graduation',
    'class_year': 'class',
    'result_40yards': "40 yard dash",
    'result_broad_jump': 'broad jump',
    'result_power_ball': 'power toss',
    'result_shuttle': '20 yard shuttle',
    'result_vertical_jump': 'vertical jump',
    'bench_press': 'bench press',
    'star_rating': 'star rating'
}
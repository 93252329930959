import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import shvlLogo from './shvl_logo.png'
import hangryLogo from './hangry_logo.png'
import ramenLogo from './logo.png'
import styles from './styles';
import Icon from '@material-ui/core/Icon';
import SearchForm from "../SearchForm/SearchForm";
import CustomizedTabs from "../CustomizedTabs/CustomizedTabs";
import load from "../../load";


const Header = (props) => {
    console.log("Header is rendering", props.ingredients);
    const {classes} = props;
    const [searchResults, setResults] = useState(props.results);
    // console.log("searchResults in Header are: ", searchResults)
    const [loading, setLoading] = useState(undefined);
    const handlerSearchData = (datalist, loading) => {
        // console.log("submit button clicked: made it to handlerSearchData", datalist)
        setResults(datalist)
    };

    return (
        <div className={classes.headerWrap}>
            <div className={classes.headerTop}>
                <div className={classes.logoWrap}>
                    <img src={ramenLogo} alt="Logo" className={classes.logo}/>
                </div>
                <SearchForm handlerSearch={handlerSearchData}
                            query={props.query}
                            fetchData={props.fetchData}
                            inputDataExist={props.inputDataExist}

                />
                <div className={classes.headerRightWrapper}>
                    <Icon className={classes.icon}>menu</Icon>
                </div>
            </div>
            <div>
                <CustomizedTabs
                    results={props.results}
                    intent={props.intent}
                    ingredients={props.ingredients}
                    loading={props.loading}
                />
            </div>
        </div>
    );
};
export default withStyles(styles)(Header);
const styles = theme => ({
    headerWrap: {
        paddingTop: '16px',
        backgroundColor: '#161616',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '10px'
        },
    },
    headerTop: {
        minHeight: '46px',
        display: 'flex'
    },
    logoWrap: {
        display: 'block'
    },
    logo: {
        marginLeft: '27px',
        height: '47px',
        width: '47px',
        marginRight: '25px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px'
        },
    },
    headerRightWrapper: {
        display: 'flex',
        marginLeft: 'auto',
        height: '46px',
    },
    SelectPrivateWrapper: {
        position: 'relative',
        bottom: '4px',
        [theme.breakpoints.down('xs')]: {
            width: 6,
        },
    },
    icon: {
        color: '#a5a5a5',
        cursor: 'pointer',
        padding: '5px',
        marginRight: '10px',
        marginLeft: 10,
        marginTop: '8px',
        fontSize: '21px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 16,
        },
        '&:hover': {
            background: '#6d6d6d',
            color: 'white',
            borderRadius: '5px',
        },
    }
});

export default styles;
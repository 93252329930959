const styles = theme => ({
    root:{
        marginLeft: 100,
        marginTop: '1rem',
        [theme.breakpoints.down('md')]: {
            marginLeft: 100
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 65
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 15
        },
    },
    chip: {
        marginRight: '1rem',
        marginBottom: '15px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '6px',
            paddingRight: '6px',
            fontSize: '12px'
        }
    },
    label:{
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '1px',
            paddingRight: '1px'
        },
    }
});

export default styles;
import green from '@material-ui/core/colors/green';

const styles = theme => ({
    paper: {
        width: '100%',
        margin: 0,
        position: 'absolute',
        top: '0',
        borderRadius: 0,
        paddingBottom: 20,
        backgroundColor:"#161616"
    },
    dialogTitle: {
        textAlign: 'center',
        color:"#cccccc"
    },
    closeicon: {
        display: 'block',
        position: 'absolute',
        right: 0,
        cursor: 'pointer'
    },
    fab: {
        backgroundColor: "#ffcf00",
        width: 90,
        height: 90,
        margin: "0 auto",
        '&:hover': {
            backgroundColor: "#d9b106",
        }
    },
    micIcon: {
        color: "white",
    }
});

export default styles;
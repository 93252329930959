import React from 'react';
import ReactRating from 'react-rating';

const Rating = (props) => {
    return(
        <div>
            <ReactRating
                initialRating={props.player.star_rating}
                readonly
                emptySymbol={<i className={`${props.classes.faStar} far fa-star faStar`}></i>}
                fullSymbol={<i className={`${props.classes.faStar} fas fa-star faStar`}></i>}
            />
            <span className={props.classes.ratingScore}>Rating {props.player.star_rating}</span>
        </div>
    );
}

export default Rating;
import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const IngredientSlider = (props) => {
    console.log("IngredientSlider is rendering");
    const {classes} = props;
    const data = props.ingredients;
    const [currentResults, setCurrentResults] = useState(data);

    const settings = {
        dots: false,
        infinite: false,
        speed: 100,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            }
        ]
    };

    useEffect(() => {
        setCurrentResults(data);
        console.log('DATA is:', data)
    }, [data])

    if (!data) {
        console.log('DATA is NULL:', data)
        return null
    }

    const SlideItems = (data) => {
        console.log("SlideItem DATA is:", data['data'])
        return (
            <Slider {...settings}>
                {
                    data['data'].map((slide) => {
                            return (
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            {slide}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )
                        }
                    )
                }
            </Slider>
        )
    };

    const sliderHeadings = Object.entries(currentResults).map((entries, index) => {
        console.log('ENTRIES are:', entries);
        return (
            <div key={index}>
                <div key={index}>{entries[0]}</div>
                <SlideItems data={entries[1]}/>
            </div>
        )
    });

    return (
        <div>
            {sliderHeadings}
        </div>
    );
};

export default withStyles(styles)(IngredientSlider);
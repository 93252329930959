const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#161616",
    },
    tabsRoot: {
        paddingLeft: 110,
        minHeight: '37px',
        height: '37px',
        backgroundColor: '#161616',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 18,
        }
    },
    tabsIndicator: {
        backgroundColor: "#FFF"
    },
    tabRoot: {
        textTransform: "initial",
        minWidth: 20,
        color: "#FFF",
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(0),
        minHeight: '38px',
        padding: '0 7px',
        letterSpacing: 0,
        "&:hover": {
            color: "#FFF",
            opacity: 1
        },
        "&$tabSelected": {
            color: "#FFF",
            padding: '0 7px',
            fontWeight: 700
        },
        "&:focus": {
            color: "#FFF"
        }
    },
    tabSelected:{
        fontWeight: 500
    },
    labelCustom: {
        padding: '0 3px',
    },
});

export default styles;
import {withStyles} from '@material-ui/core/styles';
import React, {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/core/Icon';
import VoiceSearch from '../VoiceSearch/VoiceSearch';
import styles from './styles';
import annyang from 'annyang';
import {useHistory, useLocation} from "react-router-dom";


const SearchForm = props => {
    console.log("SearchForm is rendering");
    const {classes} = props;
    const [query, setQuery] = useState(props.query);
    const [hasError, setErrors] = useState(false)
    const [inputDataExist, setInputDataExist] = useState(0);
    const [dataExist, setDataExist] = useState('');
    const history = useHistory()
    const location = useLocation()

    const handleChange = event => {
        setQuery(event.target.value);
        setInputDataExist(1);
        if (event.target.value === '') {
            setInputDataExist(0);
        }
    };

    const deleteInputData = () => {
        setQuery("");
        setInputDataExist(0);
    };

    const handleSubmit = async (event) => {
        console.log("handledSubmit called")
        event.preventDefault();
        history.push(`/?q=${query}`)
        props.fetchData(query)
        document.getElementById("myForm").blur();
        //props.handlerSearch(null, true);
        // const dataList = await load(`https://api.shvl.com/search/?q=${query}`)
        // console.log(dataList)
    };

    const updateSearchInput = async (voiceValue) => {
        if(voiceValue === 'Please Say Anything'){
            voiceValue = '';
            return false
        };
        setQuery(voiceValue);
        setInputDataExist(1);
        setDataExist(voiceValue);

        history.push(`/?q=${voiceValue}`)
        props.fetchData(voiceValue)

    };

    useEffect(() => {

        // This how we update the searchForm input field with the query string if entered from address bar

        console.log("useEffect fired")
        const queryString = decodeURI(location.search.split('q=')[1])
        console.log('Updating searchForm input with Query: ', queryString);
        setQuery(queryString)
        setInputDataExist(props.inputDataExist);

    }, [location])
    return (

        <form onSubmit={handleSubmit} className={classes.form}>
            <Paper className={classes.root} elevation={1}>
                <InputBase className={classes.input}
                           onChange={handleChange}
                           value={query || ""}
                           name='q'
                           id='myForm'
                />
                {inputDataExist ?
                    <IconButton className={classes.iconButton} aria-label="Search" onClick={deleteInputData}>
                        <SearchIcon className={classes.iconHover}>close</SearchIcon>
                    </IconButton> :
                    null
                }
                {!annyang ?
                    <IconButton className={classes.iconButton} aria-label="Search" onClick={props.updateTermByClick}>
                        <SearchIcon className={classes.iconHover}>search</SearchIcon>
                    </IconButton> :
                    <VoiceSearch classes={classes} updateSearchInput={updateSearchInput}/>
                }
            </Paper>
        </form>

    );
};
export default withStyles(styles)(SearchForm);
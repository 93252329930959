import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
// import Modal from '../Filters/Modal/Modal';
import Chip from '@material-ui/core/Chip';
import DictionaryKeys from '../../ChipKeys';
import DictionaryValues from '../../ChipsValues';

const Filters = (props) => {
    const [open, setOpen] = useState(false);
    const {classes} = props;

    if (!props.data.length) {
        return (<p style={{textAlign: 'center'}}>No data for your request. Sorry :(</p>);
    }


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const replacementKeys = DictionaryKeys;
    const replacementValues = DictionaryValues;
    const objIntent = props.intent;
    delete objIntent.query;

    const replacedKeysValues = Object.entries(objIntent).map((entries) => {
        const newKey = replacementKeys[entries[0]] || entries[0];
        const newValue = replacementValues[entries[1]] || entries[1];
        return {[newKey]: [newValue]};
    });

    const showChips = replacedKeysValues.map((key, index) => {
        const keys = Object.keys(key);
        const values = Object.values(key);

        return (
            <Chip
                key={index}
                label={`${keys}: ${values}`}
                onClick={handleClickOpen}
                color="primary"
                clickable
                variant="outlined"
                classes={{root: classes.chip, label: classes.label}}
            />
        )
    });

    return (
        <div className={classes.root}>
            {showChips}
            {/*<Modal open={open} handleClose={handleClose}/>*/}
        </div>
    );
}

export default withStyles(styles)(Filters);

const styles = theme => ({
    oldroot: {
        padding: '10px 0px',
        background: "#ffffff",
        backgroundColor: '#161616',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '5px 10px'
        }
    },

    root: {
        backgroundColor: '#161616',
        height: '100%',

    },

    cardMedia:{


    },

    title:{
        color:"#cccccc",
        fontSize: 14,
        fontWeight: 600,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        margin: 0,
        paddingBottom: ".25em"

    },

    ingredientText:{
        color:"#cccccc",
        fontSize: 12,
        // textOverflow: 'ellipsis',
        // whiteSpace: 'wrap',
        // overflow: 'hidden',
        display:"block",
        paddingBottom: '.25em',

    },

    cardContent:{

        backgroundColor: "#282828",
        height: '100%',

    },



    gridTile: {
        padding: '12px 6px 0 6px'
    },

    myGrid: {
        margin: 0,
        padding: 5,

    },

    tile: {
        boxSizing: 'border-box',
        borderRadius: '4px',
        backgroundColor: '#fff',
        overflow: 'hidden',
        boxShadow: '0 2px 3px rgba(0,0,0,0.06)',
        border: '1px solid #161616',
        borderBottomColor: '#282828',
        padding: 0,
        width: '100%',
    },
    tileMedia: {
        margin: 'auto',
        display: 'block',
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        position: 'relative'
    },
    tileMediaImg: {
        maxWidth: '100%',
        display: 'block',
    },

    recipeText: {
        color: '#CCCCCC',
        backgroundColor: '#282828'
    },
    tileTitle: {
        margin: 0,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    tileBody: {
        paddingBottom: '.8em',
        padding: '1em',
        wordWrap: 'break-word',
        wordBreak: 'initial',
        overflow: 'hidden',
        backgroundColor: "#282828"
    },
    tileFavicon: {
        maxWidth: '16px',
        maxHeight: '16px',
        marginTop: '-1px',
        verticalAlign: 'middle',
        marginRight: '.5em'
    },
    pagination: {
        textAlign: 'center',
        fontSize: '14px',
        color: '#545454',
        paddingLeft: 0,
        '& li': {
            display: 'inline-block',
            borderRadius: '5px',
            margin: '0.3rem',
            '& a': {
                '& :hover, &:focus, & :active': {
                    outline: 'none',
                },
                cursor: 'pointer',
                display: 'inline-block',
                padding: '0.3rem 0.6rem',
                borderRadius: '3px',
            }
        },
        '& li.previous.disabled': {
            display: 'none'
        },
        '& li.next.disabled': {
            display: 'none'
        }
    },
    videoSource: {
        fontSize: '.87em',
        color: '#CCCCCC',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.87em'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.87em'
        }
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    buttonShowMore: {
        width: '100%',
        color: "#cccccc",
        textTransform: 'none',
        fontWeight: 600,
        borderColor: '#161616',
        backgroundColor: '#333333',
        padding: 10,
        margin: '25px 0px 25px 0px'
    }
});

export default styles;
import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Microphone from '@material-ui/icons/Mic';
import VoiceDialogWrapped from '../VoiceSearchPopup/VoiceSearchPopup'
import annyang from 'annyang/src/annyang';

const VoiceSearch = props => {

    const {classes} = props;
    const [open, setOpen] = useState(false);
    let [voiceValue, setVoiceValue] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
        setVoiceValue('Listening for Ingredients');
        if (annyang) {
            annyang.setLanguage('en');
            annyang.start();
            annyang.addCallback('result', (phrases) => {
                setVoiceValue('');
                let value = phrases[0];
                setOpen(true);
                setVoiceValue(value);
            })
        }
    };
    const handleClose = value => {
        setOpen(false);
        props.updateSearchInput(voiceValue);
        if (annyang) {
            annyang.abort();
        }
    };
    const closeButtonHandler = () => {
        setOpen(false);
        setVoiceValue('');
        annyang.abort();
    };

    return (

        <div>
            <IconButton
                aria-label="Microphone"
                className={classes.iconMic}
                onClick={handleClickOpen}
            >
                <Microphone/>
            </IconButton>
            <VoiceDialogWrapped
                open={open}
                onClose={handleClose}
                onCloseButton={closeButtonHandler}
                voiceValue={voiceValue}
            />
        </div>

    );
};
export default VoiceSearch;
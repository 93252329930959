const styles = theme => ({
    athleteWrapper:{
        padding: '0 100px 0 100px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 64px 0 64px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 16px 0 16px'
        }
    },
    searchResult:{
        paddingBottom: '24px'
    },
    resultsCount: {
        marginLeft: 100,
        marginBottom: 5,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 65,
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 15,
        },
    },
    fullNamePosition:{
        display: 'inline-block',
        fontWeight: 500,
        fontSize: '18px',
        color: '#4d489c',
        cursor: 'pointer',
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px'
        }
    },
    geoPosition:{
        paddingTop: '3px',
        color: '#548162',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        }
    },
    description:{
        paddingTop: '3px',
        color: '#545454',
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        }
    },
    ratingScore:{
        paddingLeft: '10px',
        color: '#808080',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        }
    },
    faStar:{
        paddingTop: '5px',
        color: '#e7701b'
    },
    pagination:{
        textAlign: 'center',
        fontSize: '14px',
        color: '#545454',
        paddingLeft: 0,
        '& li': {
            display: 'inline-block',
            borderRadius: '5px',
            margin: '0.3rem',
            '& a': {
                '& :hover, &:focus, & :active':{
                    outline: 'none',
                },
                cursor: 'pointer',
                display: 'inline-block',
                padding: '0.3rem 0.6rem',
                borderRadius: '3px',
            }
        },
        '& li.previous.disabled': {
            display: 'none'
        },
        '& li.next.disabled': {
            display: 'none'
        }
    },
    active:{
        background: '#4fba6f',
        color: 'white',
        outline: 'none'
    }
});

export default styles;
import React, {useState, useEffect} from 'react';
import Header from "./components/Header/Header";
import {useLocation} from "react-router-dom";

function App() {

    console.log("Main App is rendering");
    const location = useLocation();
    const queryString = decodeURI(location.search.split('q=')[1])
    console.log('queryString from location is: ', queryString);

    const [hasError, setErrors] = useState(false)
    const [searchResults, setResults] = useState(undefined);
    const [ingredients, setIngredients] = useState(undefined);
    const [searchIntent, setIntent] = useState(undefined);
    const [loading, setLoading] = useState(0);
    const [inputDataExist, setInputDataExist] = useState(1);

    async function fetchData(query) {
        const res = await fetch(`https://ngv5er78c2.execute-api.us-east-1.amazonaws.com/dev/search/?q=${query}`);
        res.json()
            .then(res => {

                    setResults(res.results);
                    console.log('res.results in App is:', res.results)
                    setIngredients(res.ingredients)
                    console.log('res.ingredients in App is:',ingredients)
                    // setIntent(res.intent);
                    // console.log('props.intent in App is:', res.intent)
                    setLoading(1)
                    // setInputDataExist(1)
                }
            )
            .catch(err => setErrors(err));
    }

    useEffect(() => {
            fetchData(queryString)
        }, [location]
    );
    return (
        <div>
            <Header results={searchResults}
                    intent={searchIntent}
                    loading={loading}
                    ingredients={ingredients}
                    query={queryString}
                    fetchData={fetchData}
                    inputDataExist={inputDataExist}
            />

        </div>
    );
}

export default App;

export default {
    'right defensiveends': 'defensive lineman', 'defensive linemans': 'defensive lineman',
    'offensivetackle': 'offensive lineman', 'left defensive end': 'defensive lineman', 'k': 'kicker',
    'defensive back': 'defensive back', 'cornerbacks': 'defensive back', 'halfbackers': 'running back',
    'inside line backers': 'linebacker', 'centers': 'offensive lineman', 'lineman': 'offensive lineman',
    'offensiveguards': 'offensive lineman', 'runningbacks': 'running back', 'fullback': 'running back',
    'dl': 'defensive lineman', 'right defensive ends': 'defensive lineman', 'punters': 'punter',
    'linebackers': 'linebacker', 'defensiveback': 'defensive back', 'running back': 'running back',
    'offensive lineman': 'offensive lineman', 'defensive tackle': 'defensive lineman',
    'nose tackles': 'defensive lineman', 'outside line backers': 'linebacker', 'halfbacker': 'running back',
    'strongsafeties': 'defensive back', 'fullbacker': 'running back', 'de': 'defensive lineman',
    'runningback': 'running back', 'strong safety': 'defensive back', 'lb': 'linebacker',
    'widereceiver': 'wide receiver', 'c': 'offensive lineman', 'full back': 'running back',
    'olb': 'linebacker', 'defensive backs': 'defensive back', 'tightend': 'tight end',
    'wr': 'wide receiver', 'right defensiveend': 'defensive lineman', 'free safety': 'defensive back',
    'left outside line backer': 'linebacker', 'right defensive end': 'defensive lineman', 'p': 'punter',
    'tight end': 'tight end', 'middle linebacker': 'linebacker', 'dt': 'defensive lineman',
    'offensivelinemans': 'offensive lineman', 'offensive tackle': 'offensive lineman',
    'ot': 'offensive lineman', 'le': 'defensive lineman', 'left defensiveends': 'defensive lineman',
    'nt': 'defensive lineman', 'offensivetackles': 'offensive lineman', 'ol': 'offensive lineman',
    'te': 'tight end', 'hb': 'running back', 'defensive ends': 'defensive lineman',
    'quarterback': 'quarterback', 'full backs': 'running back', 'nosetackle': 'defensive lineman',
    'defensiveends': 'defensive lineman', 'fullbackers': 'running back', 'tightends': 'tight end',
    'right outside linebacker': 'linebacker', 'full backers': 'running back',
    'left defensiveend': 'defensive lineman', 'db': 'defensive back', 'free safeties': 'defensive back',
    'half backers': 'running back', 'corner back': 'defensive back', 'wide receivers': 'wide receiver',
    'defensive tackles': 'defensive lineman', 'defensive end': 'defensive lineman', 'ilb': 'linebacker',
    'safeties': 'defensive back', 'defensive lineman': 'defensive lineman',
    'right outside line backer': 'linebacker', 'inside linebacker': 'linebacker',
    'half back': 'running back', 'og': 'offensive lineman', 'inside linebackers': 'linebacker',
    'left outside linebackers': 'linebacker', 'half backer': 'running back',
    'offensive linemans': 'offensive lineman', 'corner backs': 'defensive back',
    'half backs': 'running back', 'middle line backer': 'linebacker', 'quarter back': 'quarterback',
    'offensive guards': 'offensive lineman', 'fs': 'defensive back', 'cornerback': 'defensive back',
    'middle line backers': 'linebacker', 'middle linebackers': 'linebacker', 'cb': 'defensive back',
    'offensiveguard': 'offensive lineman', 'defensivelinemans': 'defensive lineman',
    'fullbacks': 'running back', 'right outside linebackers': 'linebacker',
    'outside linebacker': 'linebacker', 'full backer': 'running back', 'line backer': 'linebacker',
    'center': 'offensive lineman', 'wide receiver': 'wide receiver',
    'defensivetackles': 'defensive lineman', 'strong safeties': 'defensive back', 'qb': 'quarterback',
    'safety': 'defensive back', 'defensivebacks': 'defensive back', 'widereceivers': 'wide receiver',
    'nose tackle': 'defensive lineman', 'kicker': 'kicker', 'offensive guard': 'offensive lineman',
    'quarter backs': 'quarterback', 'offensive tackles': 'offensive lineman', 'lolb': 'linebacker',
    'mlb': 'linebacker', 'right outside line backers': 'linebacker',
    'left outside line backers': 'linebacker', 'nosetackles': 'defensive lineman',
    'quarterbacks': 'quarterback', 'left outside linebacker': 'linebacker',
    'offensivelineman': 'offensive lineman', 'defensivelineman': 'defensive lineman',
    'outside line backer': 'linebacker', 'running backs': 'running back',
    'defensiveend': 'defensive lineman', 'halfbacks': 'running back', 'freesafeties': 'defensive back',
    'defensivetackle': 'defensive lineman', 'outside linebackers': 'linebacker', 'kickers': 'kicker',
    'fb': 'running back', 'halfback': 'running back', 'ss': 'defensive back', 'tight ends': 'tight end',
    'linebacker': 'linebacker', 'line backers': 'linebacker', 'punter': 'punter', 're': 'defensive lineman',
    'rb': 'running back', 'rolb': 'linebacker', 'left defensive ends': 'defensive lineman',
    'inside line backer': 'linebacker', 'long jumper': 'long jumper', 'recruit': 'recruit',
    'ak': 'alaska', 'al': 'alabama', 'ar': 'arkansas', 'as': 'american samoa', 'az': 'arizona',
    'ca': 'california', 'co': 'colorado', 'ct': 'connecticut', 'dc': 'district of columbia',
    'fl': 'florida', 'ga': 'georgia', 'gu': 'guam', 'hi': 'hawaii', 'ia': 'iowa',
    'id': 'idaho', 'il': 'illinois', 'in': 'indiana', 'ks': 'kansas', 'ky': 'kentucky', 'la': 'louisiana',
    'ma': 'massachusetts', 'md': 'maryland', 'me': 'maine', 'mi': 'michigan', 'mn': 'minnesota',
    'mo': 'missouri', 'mp': 'northern mariana islands', 'ms': 'mississippi', 'mt': 'montana',
    'na': 'national', 'nc': 'north carolina', 'nd': 'north dakota', 'nb': 'nebraska', 'nh': 'new hampshire',
    'nj': 'new jersey', 'nm': 'new mexico', 'nv': 'nevada', 'ny': 'new york', 'oh': 'ohio', 'ok': 'oklahoma',
    'or': 'oregon', 'pn': 'pennsylvania', 'pr': 'puerto rico', 'ri': 'rhode island', 'sc': 'south carolina',
    'sd': 'south dakota', 'tn': 'tennessee', 'tx': 'texas', 'ut': 'utah', 'va': 'virginia',
    'vi': 'virgin islands', 'vt': 'vermont', 'wa': 'washington', 'wi': 'wisconsin', 'wv': 'west virginia',
    'wy': 'wyoming'
}
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';

const VoiceDialogWrapped = props => {
    const {classes, onClose, selectedValue, onCloseButton, voiceValue, ...other} = props;
    const handleClose = () => {
        onClose(props.selectedValue);
    };
    const handleListItemClick = value => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" {...other} maxWidth='xl'
                classes={{paper: classes.paper}}>
            <CloseIcon onClick={onCloseButton} className={classes.closeicon}/>
            <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>{props.voiceValue}</DialogTitle>
            <Fab color="default" onClick={() => handleListItemClick('Voice Value')} aria-label="Edit"
                 className={classes.fab}>
                <Icon className={classes.micIcon}>mic</Icon>
            </Fab>
        </Dialog>
    );

};

export default withStyles(styles)(VoiceDialogWrapped);
import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
    return(
        <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={Math.ceil(props.pageCount)}
            onPageChange={props.handlerPageClick}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            containerClassName={props.classes.pagination}
            subContainerClassName={'pages pagination'}
            activeClassName={props.classes.active}
        />
    )
};
export default Pagination;
import React, {useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import styles from './styles';
import Rating from '../Rating/Ratings';
import Pagination from '../Pagination/Pagination';
import Filters from '../Filters/Filters';

const SearchResults = (props) => {
    const {classes} = props;
    const data = props.results;
    console.log('props.results in searchResults is:', props.results)
    const intent = props.intent;
    // console.log('props.intent in searchResults is:', props.intent)
    const [currentPage, setCurrentPage] = useState(1);
    const [todosPerPage] = useState(10);

    if (!data || !intent) {
        return null
    }

    const currentResults = data;
    const handlerPageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const pageCount = currentResults.length / todosPerPage;
    const showCurrentResults = currentResults.slice(indexOfFirstTodo, indexOfLastTodo);

    const players = showCurrentResults.map((player, index) => {
            return (
                <div key={index} className={classes.athleteWrapper}>
                    <div className={classes.searchResult}>
                        <div
                            className={classes.fullNamePosition}>{player.first_name} {player.last_name} | {player.primary_position_name}</div>
                        <div className={classes.geoPosition}>{player.high_school} ({player.city}, {player.state_abbr})</div>
                        <div className={classes.description}>
                            {player.class_year ? player.class_year + ", " : null} {player.graduation_year ? "Class Of " + player.graduation_year + ", " : null}Height: {Math.floor(player.height / 12)}' {Math.floor(player.height % 12)}",
                            Weight: {player.weight}

                            {<br/>}
                            40
                            yards: {player.result_40yards} Vertical: {player.result_vertical_jump} Shuttle: {player.result_shuttle} Power
                            Toss: {player.result_power_ball}
                        </div>
                        <Rating
                            player={player}
                            classes={classes}
                        />
                    </div>
                </div>
            )
        }
    );
    return (
        <div>
            <Filters data={data}
                     intent={intent}
            />
            {players}
            {currentResults.length > 10 ?
                <Pagination
                    classes={classes}
                    pageCount={pageCount}
                    handlerPageClick={handlerPageClick}
                /> :
                null}
        </div>
    )

};

SearchResults.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchResults);